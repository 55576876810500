import { Grid, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { Txt } from '../components/Txt'

export const Head = () => (
    <SEO
        title="低価格で高品質が人気のミスプラチナ。先輩カップルの声をご紹介"
        description="安い結婚指輪、婚約指輪は試着もできる安心通販のMissPlatinum。実際に購入された先輩カップルをご紹介"
        page="review"
    />
)
const ReviewPage: React.FC = () => {
    const muiTheme = useTheme()
    return (
        <Layout breadcrumbs={[{ name: '先輩カップルの声' }]}>
            <Grid container spacing={0}>
                <Grid item sm={2} />
                <Grid item sm={8} xs={12} component="main">
                    <H1>
                        <StaticImage
                            src="../images/review/review-0.jpg"
                            alt="ご購入頂いたお客様のコメント"
                            width={640}
                        />
                    </H1>
                    <Wrapper theme={muiTheme}>
                        <H2 theme={muiTheme}>
                            <Txt size="s">
                                ①婚約指輪が実現できた高品質で安い結婚指輪
                            </Txt>
                        </H2>
                        <Body theme={muiTheme}>
                            <ImageWrapper>
                                <StaticImage
                                    src="../images/review/review-1.jpg"
                                    alt=""
                                    width={190}
                                    imgStyle={{ width: '190px' }}
                                    objectFit="contain"
                                />
                            </ImageWrapper>
                            <Description>
                                <Txt size="s">
                                    結婚指輪ってどうやって決めればいいかわからなかったので、とにかく品質とデザインをネットでチェックしていたらmissplatinumにたどり着きました。一目見た時にプラチナ900で素材、デザイン、ともに納得いく指輪だったのですが、価格の安さに驚きました。プラチナでこんなに安い価格は他になかったのですが、メーカー直売のネットショップとあり安心。品質は落とさず予算が浮いた分、婚約指輪もお願いしました。
                                </Txt>
                                <br />
                                <Txt size="s">
                                    婚約指輪は諦めていたので大満足です。
                                </Txt>
                            </Description>
                        </Body>
                    </Wrapper>
                    <Wrapper theme={muiTheme}>
                        <H2 theme={muiTheme}>
                            <Txt size="s">②プラチナではここが一番</Txt>
                        </H2>
                        <Body theme={muiTheme}>
                            <ImageWrapper>
                                <StaticImage
                                    src="../images/review/review-2.jpg"
                                    alt=""
                                    width={190}
                                    imgStyle={{ width: '190px' }}
                                    objectFit="contain"
                                />
                            </ImageWrapper>
                            <Description>
                                <Txt size="s">
                                    結婚指輪にはお金をかけないつもりでしたが、記念品なのでプラチナでデザインが気に入ったものを買いたいと思っていました。品質、デザイン、価格の3拍子揃ったのが決めて。{' '}
                                </Txt>
                                <br />
                                <Txt size="s">
                                    式場とも提携している特価リングだったので即決めました。
                                </Txt>
                            </Description>
                        </Body>
                    </Wrapper>
                    <Wrapper theme={muiTheme}>
                        <H2 theme={muiTheme}>
                            <Txt size="s">③賢い買い物</Txt>
                        </H2>
                        <Body theme={muiTheme}>
                            <ImageWrapper>
                                <StaticImage
                                    src="../images/review/review-3.jpg"
                                    alt=""
                                    width={190}
                                    imgStyle={{ width: '190px' }}
                                    objectFit="contain"
                                />
                            </ImageWrapper>
                            <Description>
                                <Txt size="s">
                                    安い方がいいけど、妥協したくない結婚指輪
                                </Txt>
                                <br />
                                <Txt size="s">
                                    正直悩みました。いろんなサイトや雑誌を穴があくほど見て、お店にも見学に行きました。色々調べた結果、品質が良ければネットで購入が良いかもと思い。MissPlatinumの試着サロンで相談。流通や原価のお話を聞いて、安かろう悪かろうではない業界の現実を知ることができてよかったです。今後もファッションリングや贈り物、記念日などでも利用したいと思います。
                                </Txt>
                            </Description>
                        </Body>
                    </Wrapper>
                </Grid>
            </Grid>
        </Layout>
    )
}

const Wrapper = styled('article')(({ theme }) => ({
    margin: '40px 8px 0',
    border: `1px solid ${theme.palette.secondary.main}`,
}))
const H1 = styled('h1')({
    margin: 0,
})
const H2 = styled('h2')(({ theme }) => ({
    margin: 0,
    padding: '10px',
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    lineHeight: 1,
}))
const Body = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
    },
}))
const ImageWrapper = styled('div')({
    minWidth: '190px',
    display: 'flex',
    justifyContent: 'center',
    margin: '12px',
})
const Description = styled('p')({
    margin: '12px',
})

export default ReviewPage
